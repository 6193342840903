<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>盘前简报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <div class="title" style="fontWeight:bold">盘前简报选择</div>
      <div class="search-content">
        <div style="display:flex;">
          <div style="display:flex;align-items:center;">
            <div style="width:100px;">日期</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in dateList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <!-- <el-date-picker v-model="value" type="date" placeholder="选择日期"></el-date-picker> -->
          </div>
          <div class="search-btn" @click="getFutureAllDate">确定</div>
        </div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">盘前简报设置</div>
      </div>
      <div class="content">
        <div class="title">大盘预测</div>
        <el-form ref="form" label-position="left" label-width="120px" class="policy-content-form">
          <el-form-item v-for="item in indexForecast" :key="item.name" :label="item.name ==='上证指数'?'上证上涨概率':'创业板上涨概率'">
            <el-input placeholder="请输入0-100之间的正整数" oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100)value=100;if(value<=0)value=null" v-model.trim="item.coefficient" style="width: 180px;"></el-input>
            <div style="display:inline-block;marginLeft:-20px;z-index:999">%</div>
          </el-form-item>
        </el-form>
        <div class="title">板块预测</div>
        <div class="block">
          <div v-for="(item,index) in sectorForecast" :key="index">
            <div class="number">{{index +1}}</div>
            <div class="select">
              <el-select v-model="item.name" placeholder="请选择" style="width: 150px">
                <el-option v-for="list in dcIndustryList" :key="list" :disabled="checkInclude(list)" :label="list" :value="list">
                </el-option>
              </el-select>
            </div>
            <div>
              <el-input placeholder="请输入0-100之间的正整数" oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100)value=100;if(value<=0)value=null" v-model.trim="item.riseProbability" style="width: 180px" maxlength="10">
              </el-input>
              <div style="display:inline-block;marginLeft:-20px;marginTop:8px;z-index:999">%</div>
            </div>
          </div>
        </div>
        <div class="title">个股预测</div>
        <div class="stockForecast">
          <div v-for="(item,index) in stockForecast" :key="index">
            <el-select @change="change(index)" @focus="focus" v-model="item.name" filterable :filter-method="filterCheckPerOptions" placeholder="请选择" style="width: 150px">
              <el-option v-for="list in selestStockList" :key="list.code" :disabled="checkIncludes(list.name)" :label="list.name" :value="list.name">
                <span style="float: left">{{ list.code }}</span>
                <span style="display:inline-block;width:6px"></span>
                <span>{{ '['+list.name+']' }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="sureBtn">
        <el-button @click="sureEdit">确认修改</el-button>
        <span @click="getDetail(true)">使用缺省数据</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as echarts from 'echarts';
import { ElMessage } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      shangIncrease: null,
      boardIncrease: null,
      options: [],
      value: '',
      dateList: [],
      indexForecast: [],
      sectorForecast: [],
      stockForecast: [],
      dcIndustryList: [],
      allStockList: [],
      selestStockList: []
    })


    // 获取所有板块
    const getDcIndustryList = () => {
      proxy.$get('/cts/back/preOrder/dcIndustryList').then((res) => {
        if (res.resultState === '200') {
          state.dcIndustryList = res.data
        }
      }).catch((err) => {

      });
    }
    getDcIndustryList()
    // 获取所有股票
    const getAllStock = () => {
      proxy.$get('/cts/back/preOrder/getAllStock').then((res) => {
        if (res.resultState === '200') {
          state.allStockList = res.data
          state.selestStockList = res.data.slice(0, 30)
        }
      }).catch((err) => {

      });
    }
    getAllStock()
    // 获取日期列表
    const getDateList = () => {
      proxy.$get('/cts/back/preOrder/dateList').then((res) => {
        if (res.resultState === '200') {
          state.dateList = res.data
          state.value = res.data[0]
          getDetail(false)
        }
      }).catch((err) => {

      });
    }
    // 获取详情
    const getDetail = (item) => {
      let params = `?date=${state.value}`
      proxy.$get('/cts/back/preOrder/detail' + params).then((res) => {
        if (res.resultState === '200') {
          const { indexForecast, sectorForecast, stockForecast } = res.data
          indexForecast.map(item => {
            return item['nameInit'] = item.name
          })
          state.indexForecast = dealData('name', 'coefficient', indexForecast, item)
          state.sectorForecast = dealData('name', 'riseProbability', sectorForecast, item)
          state.stockForecast = dealData('name', 'code', stockForecast, item)
        }
      }).catch((err) => {

      });
    }
    const dealData = (name, value, resultArr, flag) => {
      let newName = ''
      let newValue = ''
      if (flag) {
        newName = name + 'Init'
        newValue = value + 'Init'
      } else {
        newName = name
        newValue = value
      }
      let arr = new Array
      let indexObj = new Object
      resultArr.forEach(item => {
        indexObj[name] = item[newName]
        indexObj[value] = item[newValue]
        arr = [...arr, { ...indexObj }]
      })
      return arr
    }
    const sureEdit = () => {
      let isNull = state.indexForecast.every(item => {
        return item.coefficient
      })
      if (!isNull) {
        ElMessage({
          message: '请输入大盘预测概率',
          type: "warning",
        });
        return
      }
      let isEmpty = state.sectorForecast.every(item => {
        return item.riseProbability
      })
      if (!isEmpty) {
        ElMessage({
          message: '请输入板块预测概率',
          type: "warning",
        });
        return
      }
      state.indexForecast.map(item => {
        return item.coefficient = +item.coefficient
      })
      state.sectorForecast.map(item => {
        return item.riseProbability = +item.riseProbability
      })
      let params = {
        date: state.value,
        indexForecast: state.indexForecast,
        sectorForecast: state.sectorForecast,
        stockForecast: state.stockForecast
      }
      proxy.$post('/cts/back/preOrder/modify', params).then((res) => {
        if (res.resultState === '200') {
          getDetail(false)
        }
      }).catch((err) => {

      });
    }
    const filterCheckPerOptions = (query = '') => {
      // query是输入框中的检索条件
      var arr = state.allStockList.filter(item => {
        return item.name.includes(query) || item.code.includes(query)
      })
      // 根据检索条件筛选出来的选项，只取前30条
      if (arr.length > 30) {
        state.selestStockList = arr.slice(0, 30)
      } else {
        state.selestStockList = arr
      }

    }
    const focus = (index) => {
      state.selestStockList = state.allStockList.slice(0, 30)
    }
    const checkInclude = (query) => {
      return state.sectorForecast.some(item => {
        return item.name === query
      })
    }
    const change = (index) => {
      let name = state.stockForecast[index].name
      let arr = state.allStockList.filter(item => {
        return item.name === name
      })
      state.stockForecast[index].code = arr[0].code
    }

    const checkIncludes = (query) => {
      return state.stockForecast.some(item => {
        return item.name === query
      })
    }
    onMounted(() => {
      getDateList()
    })
    return {
      ...toRefs(state),
      getDateList,
      filterCheckPerOptions,
      checkInclude,
      checkIncludes,
      sureEdit,
      getDetail,
      change,
      focus
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 20px;
  }
}
.list-container {
  margin-top: 20px;
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    &__title {
      font-weight: bold;
    }
  }
  .content {
    margin-top: 25px;
    width: 100%;
    .title {
      margin-bottom: 20px;
      font-weight: bold;
    }
    v::deep .el-input__inner {
      text-align: center;
      font-size: 12px;
    }
    .block {
      div {
        display: flex;
        margin-bottom: 5px;
      }
      .number {
        line-height: 35px;
      }
      .select {
        margin: 0 20px 0 10px;
      }
    }
    .stockForecast {
      width: 480px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        margin-bottom: 10px;
      }
    }
  }
  .sureBtn {
    margin-top: 40px;
    span {
      font-weight: bold;
      cursor: pointer;
      color: #1c4bf5;
    }
    .el-button {
      background-color: #38a28a;
      height: 40px;
      padding: 0 20px;
      color: #fff;
      font-weight: bold;
      margin-right: 20px;
    }
  }
}
</style>